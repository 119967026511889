.topnav {
  background: get-color(secondary) !important;
}
@include breakpoint(lg) {
  .topnav {
    height: $topnav-height;
    box-shadow: none !important;
    background: $body-background-color !important;
    color: $medium-gray !important;
  }
}
