.btn {
  padding: rem-calc(10);
  display: inline-block;
  vertical-align: middle;
  text-align: center;

  cursor: pointer;

  color: inherit;
  font-family: $body-font-family;
  font-size: rem-calc(12);
  font-weight: 400;

  background-color: white;

  border-radius: rem-calc(3);
  border-color: $medium-gray;
  border-style: solid;
  border-width: 1px;

  transition: all 0.3s;

  // &:hover:not(.is-active) {
  //   box-shadow: inset 0 0 1000px rgba(black, 0.1);
  // }
  & label {
    cursor: inherit;
  }

  &--circle {
    border-radius: 50%;
  }

  &.rounded {
    border-radius: rem-calc(100) !important;
    // border-color: get-color(primary) !important;
  }

  &.small {
    padding: rem-calc(6) rem-calc(12);
    font-size: rem-calc(13);
  }

  &.big {
    padding: rem-calc(14) rem-calc(24);
    font-size: rem-calc(17);
  }

  &:not(:disabled):not(.disabled).raised {
    @extend .elevate;
  }

  &:not(:disabled):not(.disabled).floater {
    @extend .elevate-high;
  }

  & i:not(:only-child) {
    margin: rem-calc(2.5);
  }
}

@include mk-component-palette('.btn');

.btn--floater {
  @extend .floater;
  border: 1px solid transparent;
}

.btn--black-hollow {
  background: transparent;
  color: $dark-gray;
  border: 1px solid $dark-gray;

  &:hover {
    border-color: initial;
  }
}

.btn {
  &--vertical {
    flex-direction: column;
  }

  &--vertical-center {
    flex-direction: column;
    justify-content: center;
  }
}

.btn__content {
  @include mk-flexbox();
  color: inherit;
  line-height: 1.4;
}

.btn__caption {
  @extend .caption;
  color: inherit !important;
  cursor: pointer;

  &.text-mutted {
    color: $medium-gray !important;
    opacity: 0.6;
  }
}

.btn__text {
  color: inherit;
}

.btn--success:not(:disabled) {
  background-color: get-color(success) !important;
  color: white !important;

  &:hover {
    background-color: darken(get-color(success), 20%) !important;
  }
}

.btn--shift {
  padding: rem-calc(20) !important;
  width: 100%;
  height: 100%;

  &>span {
    flex-direction: column;
  }

  & .icon {
    width: 100%;
    max-width: rem-calc(150);
    max-height: rem-calc(150);
    height: auto;
  }

  & .text {
    padding-top: rem-calc(10);
    font-size: 90%;
    font-weight: 500;
  }

  &.selected {
    color: $white !important;
    background-color: get-color(primary) !important;
  }

  @include breakpoint(xs only) {
    & .text {
      display: none;
    }
  }
}

.btn--company {
  position: relative;

  &.selected {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid get-color(primary);
      width: 100%;
    }
  }
}

.tabs__button {
  color: get-color(primary) !important;

  &--selected {
    color: $medium-gray !important;
  }
}

.tabs__indicator {
  background-color: get-color(primary) !important;
  height: 1px !important;
}

// .shift__button {
//   padding: rem-calc(20) !important;
//   width: 100%;
//   height: 100%;
// }
// .shift__icon {
//   width: 100%;
//   max-width: rem-calc(100);
//   max-height: rem-calc(100);
//   height: auto;
// }

.close-btn {
  position: absolute;
  top: rem-calc(5);
  right: rem-calc(5);
}