.preloader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: white;
    opacity: .75;
  }

  // & + * {
  //   transition: 0.2s all;
  //   opacity: 0.25;
  // }
}