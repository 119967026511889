/* Variables */
@import 'settings';

/* Foundation Utilities */
@import '~foundation-sites/scss/foundation';
@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;

/* Slick Slider */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Utilities */
@import 'utils/utils';
@include mk-generic-margin-padding();
@include mk-component-palette('.box');

@import 'layout/section';
@import 'layout/sidebar';
@import 'layout/grid';

@import 'components/alert';
@import 'components/btn';
@import 'components/card';
@import 'components/cards';
@import 'components/chart';
@import 'components/chip';
@import 'components/calendar';
@import 'components/dialog';
@import 'components/entries';
@import 'components/form';
@import 'components/label';
@import 'components/list';
@import 'components/login';
@import 'components/media';
@import 'components/menu';
@import 'components/topbar';
@import 'components/panel';
@import 'components/payment';
@import 'components/preloader';
@import 'components/swipeable';
@import 'components/tooltip';
@import 'components/table';

#root {
  height: 100%;
}

.link {
  position: relative;
  font-weight: bold;
  padding-bottom: rem-calc(5);
  cursor: pointer;
  transition: all 0.2s;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    border-color: transparent;
    border-style: solid;
    border-width: 0;
    transition: all 0.2s;
  }

  &--primary {
    color: get-color(primary);

    &:hover:after {
      border-bottom: 1px solid get-color(primary);
    }
  }
}

.dashboard-panel {
  padding: $gutter-base;
  padding-top: rem-calc($topnav-height + $gutter-base);
  padding-bottom: rem-calc($gutter-base * 1.5) !important;
  height: 100%;
  background-color: $body-background-color;
}

.dashboard-panel__add-icon {
  position: fixed !important;
  z-index: 9;
  bottom: rem-calc($gutter-base/2);
  right: rem-calc($gutter-base/2);
}

.tile {
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.tile__content {
  padding: rem-calc(32);
}

.tile__footer {
  padding: rem-calc(32);
}

.tile__content+.tile__footer {
  padding-top: 0;
}

.bg {
  &--success {
    background-color: get-color(success) !important;
    color: white !important;
  }

  &--danger {
    background-color: get-color(alert) !important;
    color: white !important;
  }

  &--info {
    background-color: get-color(info) !important;
    color: white !important;
  }

  &--warning {
    background-color: get-color(warning) !important;
    color: white !important;
  }
}

.text-color {
  &.text-secondary {
    color: $text-secondary;
  }

  &.success {
    color: get-color(success);
  }

  &.danger {
    color: get-color(alert);
  }

  &.warning {
    color: get-color(warning);
  }
}

.payment__button {
  padding: rem-calc(20) !important;
  width: 100%;
  height: 100%;
}

.payment__icon {
  width: 100%;
  max-width: rem-calc(100);
  max-height: rem-calc(100);
  height: auto;
}

.uppercase {
  text-transform: uppercase !important;
}

.stepper__footer-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: 5px;
  margin-top: rem-calc(25);

  & .action-btn:only-child {
    margin-left: auto;
  }
}

.journal__data {
  & p {
    margin-bottom: 0;
  }
}

.journal__data-item {
  @extend .list__item;
  border-bottom-color: get-color(light-gray) !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  & td,
  & th {
    padding: initial;
    white-space: nowrap;

    @include breakpoint(xs only) {
      padding: initial;
    }
  }
}

.journal__date {
  font-size: rem-calc(18);
  letter-spacing: rem-calc(0.5);
  font-weight: 700;
  color: get-color(primary);
  margin-bottom: rem-calc(15);
}

.journal__sale:not(:last-child) {
  border-bottom: 1px dotted rgba(black, 0.07);
}

.sale__icon {
  color: get-color(primary) !important;
}

.sale__shift {
  font-size: rem-calc(12);
  font-weight: 500;
  color: $medium-gray;
}

.sale__amount {
  font-weight: 700;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

@include breakpoint(lg) {
  .dashboard-panel {
    padding-left: rem-calc($sidebar-width + $gutter-base);
  }
}

@include breakpoint(xs only) {
  .dashboard-panel {
    padding: rem-calc($gutter-base/2);
    padding-top: rem-calc($topnav-height + $gutter-base/1.5);
  }
}

@import 'utils/generic-modifiers';


@media print {

  .sidebar,
  .dashboard-panel__add-icon {
    display: none;
  }

  .dashboard-panel {
    padding-left: $gutter-base;
  }
}