.form-group {
  min-width: rem-calc(120) !important;
  margin-bottom: rem-calc(8);
}

.input-helper {
  font-size: 80%;
  line-height: 1.2;
  margin-top: rem-calc(3);
}

.big-input {
  padding-top: rem-calc(20) !important;

  & .adornment p {
    // adornment
    font-size: rem-calc(28) !important;
    font-weight: bold;
    color: rgba(black, 0.5);
  }

  & input {
    font-size: rem-calc(28) !important;
    font-weight: bold;
  }

  & label {
    font-size: rem-calc(24) !important;
  }
}