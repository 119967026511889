/* ----------------------------------------------------------------------------
Typography from material.io
https://material.io/design/typography/the-type-system.html#
---------------------------------------------------------------------------- */
$font-size-h1:rem-calc(96);
$font-size-h2:rem-calc(60);
$font-size-h3:rem-calc(48);
$font-size-h4:rem-calc(34);
$font-size-h5:rem-calc(24);
$font-size-h6:rem-calc(20);
$font-size-subtitle_1:rem-calc(18);
$font-size-subtitle_2:rem-calc(16);
$font-size-body_1:rem-calc(16);
$font-size-body_2:rem-calc(14);
$font-size-button:rem-calc(14);
$font-size-caption:rem-calc(12);
$font-size-overline:rem-calc(10);

.h1 {
    font-size: $font-size-h1;
    font-weight: font-weight(light);
    letter-spacing: rem-calc(-1.5);
}

.h2 {
    font-size: $font-size-h2;
    font-weight: font-weight(light);
    letter-spacing: rem-calc(-0.5);
}

.h3 {
    font-size: $font-size-h3;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(0);
}

.h4 {
    font-size: $font-size-h4;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(0.25);
}

.h5 {
    font-size: $font-size-h5;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(0);
}

.h6 {
    font-size: $font-size-h6;
    font-weight: font-weight(medium);
    letter-spacing: rem-calc(0.15);
}

.subtitle_1 {
    font-size: $font-size-subtitle_1;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(0.15);
}

.subtitle_2 {
    font-size: $font-size-subtitle_2;
    font-weight: font-weight(medium);
    letter-spacing: rem-calc(0.1);
}

.body_1 {
    font-size: $font-size-body_1;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(0.5);
}

.body_2 {
    font-size: $font-size-body_2;
    font-weight: font-weight(medium);
    letter-spacing: rem-calc(0.25);
}

.button {
    font-size: $font-size-button;
    font-weight: font-weight(medium);
    letter-spacing: rem-calc(1.25);
    text-transform: uppercase;
}

.caption {
    font-size: $font-size-caption;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(0.4);
}

.overline {
    font-size: $font-size-overline;
    font-weight: font-weight(regular);
    letter-spacing: rem-calc(1.5);
    text-transform: uppercase;
}


@include breakpoint(xs) {
    .h1 {
        font-size: rem-calc($font-size-h1/2.1);
    }

    .h2 {
        font-size: rem-calc($font-size-h2/1.8);
    }

    .h3 {
        font-size: rem-calc($font-size-h3/1.5);
    }

    .h4 {
        font-size: rem-calc($font-size-h4/1.3);
    }

    .h5 {
        font-size: rem-calc($font-size-h5/1.2);
    }

    .h6 {
        font-size: rem-calc($font-size-h6/1.1);
    }

    .subtitle_1 {
        font-size: rem-calc($font-size-subtitle_1);
    }

    .subtitle_2 {
        font-size: rem-calc($font-size-subtitle_2);
    }

    .body_1 {
        font-size: rem-calc($font-size-body_1);
    }

    .body_2 {
        font-size: rem-calc($font-size-body_2);
    }

    .button {
        font-size: rem-calc($font-size-button);
    }

    .caption {
        font-size: rem-calc($font-size-caption);
    }

    .overline {
        font-size: rem-calc($font-size-overline);
    }
}