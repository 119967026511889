.table-scroller-wrapper {
    position: relative;
}

.table-scroller {
    margin-left: rem-calc(125);
    overflow-x: auto;
    padding-bottom: rem-calc(10);

    & .table__cell:first-child {
        width: rem-calc(125);
        left: 0;
        top: auto;
        position: absolute;
        margin-top: 1px;
        box-shadow: 3px 0 5px -3px rgba(0, 0, 0, 0.1);
    }

    & .table__cell.transparent:first-child {
        background: none;
        box-shadow: none;
    }

}

.table__row {

    &.sub {
        @extend .box-inset
    }

    & .entry {
        min-width: rem-calc(200);
        padding-top: 0;
        padding-bottom: 0;
    }

    td:nth-child(even) {
        background: rgba(black, .02)
    }

    &:not(.collapsed) {
        height: rem-calc(38) !important;

        & .table__cell {
            padding: rem-calc(9);
            height: rem-calc(39);
        }

        &.sub .table__cell {
            padding: 0 !important;
        }
    }

    &.collapsed {
        height: auto !important;

        & .table__cell {
            overflow: hidden;
        }
    }
}

.table__cell {

    &.number {
        text-align: right !important;
    }

}