$gutter:rem-calc(5);

.entry {
    @include mk-flexbox(row, $justify:space-between);

    &.income {
        background: rgba(20, 201, 147, 0.1);
    }

    &.predict {
        background: rgba(20, 150, 201, 0.1);
    }

    &>.container {
        @include mk-flexbox(row, $justify:space-between);

    }

    &--dialog {
        padding: rem-calc(5);
        background: $super-light-gray;
    }

    &--list-item {
        @extend .list__item;
        padding: rem-calc(5);

        &>.container--main,
        label {
            cursor: pointer;
        }
    }
}

.entry-dialog__header {
    color: white !important;
    padding: rem-calc($gutter-base) rem-calc($gutter-base) rem-calc($gutter-base/2);

    &.expense {
        background: #FF6A6A;
    }

    &.income {
        background: #14C993;
    }

    &.predict {
        background: rgb(20, 150, 201);
    }

}

.entry-dialog__title {
    font-size: rem-calc(18);
    font-weight: 600;
    color: inherit;
}

.entry__input {
    color: inherit;
    margin-top: rem-calc($gutter-base) !important;
    font-size: rem-calc(40);
    font-weight: 700;

    & ::placeholder,
    & ::-webkit-input-placeholder,
    & :-ms-input-placeholder,
    & ::-ms-input-placeholder {
        color: rgba(white, .5) !important;
    }

    &>div {
        font-size: inherit !important;
        font-weight: inherit !important;
        color: inherit !important;

        &:before,
        &:after {
            display: none !important;
        }
    }

    & input,
    & p {
        font-size: inherit !important;
        font-weight: inherit !important;
        color: inherit !important;
        text-align: right;
        background: transparent;
        border: none;
    }

    & p {
        opacity: .75;
    }

}

.entry__item {
    padding: $gutter;

    & .entry__col {
        @include mk-flexbox($justify:center);
        height: 100%;
    }
}


.entry__date {
    @include mk-flexbox($justify:center, $align:flex-start);
    height: 100%;

    & .date {
        font-weight: 700;
    }

    & .date+.recurrence-label {
        margin-top: rem-calc(5)
    }
}

.recurrence-label {
    background-color: rgba(black, .05);
    color: rgba(black, .4);
    border-radius: 3px;
    font-size: rem-calc(10);
    padding: rem-calc(3) rem-calc(5);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: rem-calc(.5)
}

.entry__account,
.entry__category {
    margin: 0;
    line-height: 1.4;
}

.entry__account {}

.entry__category {
    font-size: 85%;
    font-weight: 600;
    color: $text-secondary;
}

.entry__amount {
    font-weight: 700;
    text-align: right;
}

.entry-cel {
    padding: rem-calc(7) rem-calc(5) !important;

    & .entry__account,
    & .entry__amount {
        font-size: 80%
    }

    & .entry__amount {
        font-weight: 600;
    }

    & .entry__category {
        font-size: 70%
    }

}

.entry__action-btns {
    @include mk-flexbox($justify:center, $align: center);
}


@include breakpoint(xs only) {
    .entry {
        flex-direction: column;

        &>.container {
            flex-wrap: wrap;
        }
    }


    .entry__date {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & .date+.recurrence-label {
            margin-top: 0;
            margin-left: rem-calc(5);
        }
    }

    .entry__action-btns {
        flex-direction: row;
        justify-content: flex-start;
    }

}

.installments__container {
    background: rgba(black, 0.01);
    box-shadow: $shadow-drop-inset;
}

.installments__footer {
    padding: rem-calc(20);
}

.installment-wrapper {

    & .installment {
        padding: rem-calc(20);
        transition: all 0.2s;
    }

    &:hover .installment {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &:not(:last-child) .installment {
        border-bottom: 1px solid rgba(black, 0.05);
    }
}